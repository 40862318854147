import axios from 'axios';
import {
  HeroCard,
  SuccessMetricCard,
  FeaturedCourses,
  HowAlmaWorksCard,
  Community,
  CTA,
  WallOfLove,
  SuccessStories,
  HiringCompanies,
  Success,
} from '../components/local';

import PressHighlights from '../components/shared/PressHighlights';
import SiteHeader from '../components/shared/SiteHeader';

import { getDeviceType } from '../utils/functions/getDeviceType';
import { getStrapiURL } from '../lib/api';
import { wallOfLoveAPIUrl } from '../components/local/wall-of-love/WallOfLove';
import SeedFunding from '../components/local/seed-funding/SeedFunding';
import EventBanner from '@shared/bytes/EventBanner';
import RecognizedBy from '@components/reuseable/RecognizedBy';
import { customCourseTypeComparator } from '@utils/functions/throttle';
import { sourceType } from '@components/shared/widgets/ContactUs';
import { useDispatch } from '@globalStore/storeProvider';
import Placements from '@components/local/courses/banner/Placements';
import MainBanner from '@components/local/card/MainBanner';
import { Primary, SecondaryColor } from 'stories/Button.stories';
import ElitePartners from '@components/local/ElitePartners';
import WhyAlmaBetter from '@components/local/courses/program-features/WhyAlmaBetter';
import { getArticles, getBytesStrapiUrl } from '@lib/bytesStrapiServices';
import BytesClub from '@components/local/BytesClub';
import NewSectionTitle from '@components/shared/NewSectionTitle';

import { RecognitionCertificate } from '@components/shared/AwardsAndRecognition';
import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { RefMappedFunction } from '@lib/constants';

const apiBaseUrl = getStrapiURL();

export default function Home(props) {
  const {
    homePageData,
    error,
    testimonials,
    featuredCoursesList,
    pressHighlights,
    hiringPartnersList,
    seo,
    wallOfLove,
    coursesSummary,
    articlesData,
    tutorials,
  } = props;
  const { hero, hiringPartners, successMetrics, featuredCourses, HowAlmaWorks, AlmaCommunity } =
    homePageData?.attributes;

  const deviceType = getDeviceType();
  const sortedHiringPartnersList = customCourseTypeComparator(hiringPartnersList);
  const dispatch = useDispatch();
  const router = useRouter();


  // OPEN MODALS
  const openPlacementWidget = () => {
    dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.placement_report });
  };

  const openCallbackWidget = () => {
    dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.callback });
  };
  // DOWNLOAD
  const DownloadPlacementReport = ({ outline }) => {
    const clickHandler = () =>
      dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.placement_report });

    return (
      <>
        {outline ? (
          <SecondaryColor
            onClick={clickHandler}
            size={'large'}
            label={'Download Placement Report'}
          />
        ) : (
          <Primary onClick={clickHandler} size={'large'} label={'Download Placement Report'} />
        )}
      </>
    );
  };

  const functionsMap = useMemo(
    () => RefMappedFunction(() => { }, openPlacementWidget, openCallbackWidget),
    []
  );
  useEffect(() => {
    const path = router.asPath;

    if (path.includes('#')) {
      const hash = path.split('#')[1];

      if (hash) {
        window.addEventListener('load', () => {
          const element = document.getElementById(hash);

          const openModalFn = functionsMap[hash];

          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }

          openModalFn && openModalFn();
        });
      }
    }

    return window.removeEventListener('load', this);
  }, []);


  if (error) return <div className="container-default-1209px">Internal Server Error!</div>;

  return (
    <>
      <SiteHeader
        title={seo?.MetaTitle}
        desc={seo?.MetaDescription}
        url={seo?.MetaUrl}
        image={seo?.image?.data?.attributes?.url}
      />
      <div id="modal">
        <MainBanner />

        <div>
          <div className="px-[16px] py-[40px] md:px-[123px] md:pt-[80px]">
            <RecognitionCertificate />
          </div>
          <Placements
            title={'Launching tech careers for a better'}
            subTitle={'Tomorrow'}
            description={
              'AlmaBetter learners have transformed their lives with groundbreaking career advancements in tech.'
            }
            DownloadPlacementReport={DownloadPlacementReport}
          />
        </div>

        <HeroCard data={hero} />

        <ElitePartners />

        <FeaturedCourses
          featuredCoursesList={featuredCoursesList}
          featuredCoursesInitials={featuredCourses}
        />

        <HiringCompanies
          newHiring
          hiringPartners={sortedHiringPartnersList}
          DownloadPlacementReport={DownloadPlacementReport}
        />

        {testimonials.length > 0 && <Success stories={testimonials} degree />}

        <WhyAlmaBetter />

        <SeedFunding />

        <div className="flex flex-col gap-y-[60px] py-[40px] md:py-[80px]">
          <NewSectionTitle text={'Wall of'} highlightedText={'Love'} />
          <WallOfLove data={wallOfLove} />
        </div>

        <EventBanner />

        <BytesClub articlesData={articlesData?.data} tutorialsData={tutorials?.data} />

        <PressHighlights
          pressHighlights={pressHighlights}
          showFeatured
          showSeeMore={true}
          newHeadingLayout
        />

        <div className="container-default-1209px">
          <Community AlmaCommunity={AlmaCommunity} />
          {/* <StudentForm /> */}
          <CTA buttonText="Explore courses" buttonLink="#featuredCourses" />
        </div>
      </div>
    </>
  );
}

export async function getStaticProps(context) {
  try {
    const { data: homePageData } = await axios.get(
      `${apiBaseUrl}/api/homepage?populate=hero.heroImage,hiringPartners.icon,successMetrics.metrics.icon,successMetrics.image,featuredCourses,HowAlmaWorks.almaSteps.icon,AlmaCommunity.Community`
    );
    const { data: testimonials } = await axios.get(
      `${apiBaseUrl}/api/testimonials?filters[$and][0][videoLink][$notNull]=true&filters[rank][$notNull]=true&populate=cardImage,avatar,companyLogo`
    );
    const { data: featuredCourses } = await axios.get(
      `${apiBaseUrl}/api/courses?populate=courseIcon&populate=upcomingBatches&populate=*&filters[featuredCourse]=true&populate=courseDetails.DetailsMetric.icon`
    );
    const { data: featuredDegrees } = await axios.get(
      `${apiBaseUrl}/api/degrees?populate=courseFeatures,learningJourney.modules,courseDetails.DetailsMetric.icon`
    );
    const { data: coursesSummary } = await axios.get(`${apiBaseUrl}/api/all-courses-summary`);

    const { data: pressHighlights } = await axios.get(
      `${apiBaseUrl}/api/press-hightlight?populate[highlights][populate]=*`
    );
    const { data: hiringPartners } = await axios.get(
      `${apiBaseUrl}/api/hiring-partner?populate=partners.images`
    );

    const { data: WallOfLove } = await axios.get(wallOfLoveAPIUrl());

    const { data } = await axios.get(`${apiBaseUrl}/api/global?populate=seo.image`);

    const bytesApiBaseUrl = getBytesStrapiUrl();
    const articles = await getArticles({ page: 1, size: 9 });
    const tutorials = await axios.get(
      `${bytesApiBaseUrl}/api/tutorials?populate=tags,modules.lessons,cardImage&pagination[page]=1&pagination[pageSize]=9&sort[0]=views:desc`
    );

    return {
      props: {
        homePageData: homePageData.data || {},
        testimonials: testimonials.data || [],
        featuredCoursesList: featuredCourses.data || [],
        pressHighlights: pressHighlights.data || {},
        wallOfLove: WallOfLove || [],
        hiringPartnersList: hiringPartners.data.attributes.partners || [],
        seo: data.data.attributes.seo,
        coursesSummary: coursesSummary || [],
        articlesData: articles?.data || {},
        tutorials: tutorials?.data || {},
      },
      revalidate: 3600,
    };
  } catch (error) {
    return { props: { error: error.message } };
  }
}
