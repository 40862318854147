import React, { useState } from 'react';
import { Primary } from 'stories/Button.stories';
import { AiOutlineClose } from 'react-icons/ai';
const YearRemindBanner = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {/* Desktop Banner */}
      <div className="sticky top-[4rem] z-40 flex hidden h-[4rem] w-full   items-center justify-evenly bg-gradient-to-r from-[#FFE843] to-[#FFAD62] font-satoshi-medium text-[14px] font-medium leading-5 md:flex md:h-14 md:justify-center md:py-2 md:pr-0 md:text-sm">
        <img src="/assets/strips/banner-arrow.png" alt="Arrows" className="mr-2 h-6" />
        <div className="flex flex-wrap items-center justify-center gap-2">
          <strong className="text-center font-satoshi-bold text-[20px] leading-6">REWIND</strong>
          <p className="text-center font-satoshi-regular text-[20px] leading-6">
            our 2024 Journey &#9664;&#x25C0; How AlmaBetter created an
          </p>
          <strong className="text-center font-satoshi-bold text-[20px] leading-6">IMPACT!</strong>
          <Primary label={'Watch Now'} onClick={handleButtonClick} />
        </div>
        <img
          src="/assets/strips/banner-arrow.png"
          alt="Arrows"
          className="ml-2 h-6 scale-x-[-1] transform"
        />
      </div>
      {/* Mobile Banner */}
      <div className="sticky top-[4rem] z-40 flex h-auto w-full flex-col items-center justify-center bg-gradient-to-r from-[#FFE843] to-[#FFAD62] py-4 font-satoshi-medium text-[14px] leading-5 md:hidden">
        <div className="flex flex-col items-center justify-center ">
          {/* 1st row */}
          <div className="flex gap-2">
            <img src="/assets/strips/banner-arrow.png" alt="Arrows" className="mr-2 h-6" />
            <strong className="font-satoshi-bold text-[18px] leading-6">REWIND </strong>
            <span className="text-center font-satoshi-regular text-[16px] leading-6">
              Our 2024 Journey
            </span>
          </div>
          {/* 2nd row */}
          <div className="flex gap-2">
            <p className=" text-center font-satoshi-regular text-[16px] leading-6">
              How AlmaBetter created an
            </p>
            <span className="font-satoshi-bold text-[18px] leading-6">IMPACT! </span>
            <img
              src="/assets/strips/banner-arrow.png"
              alt="Arrows"
              className="ml-2 h-6 scale-x-[-1] transform"
            />
          </div>
          {/* 3rd row */}
          <Primary label={'Watch Now'} onClick={handleButtonClick} />
        </div>
      </div>
      {/* Video Popup  */}
      {showPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="relative w-[90%] max-w-xs p-5">
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 text-gray-600 shadow transition-all duration-200 ease-in-out hover:bg-gray-200 hover:text-gray-800 hover:shadow-md"
            >
              <AiOutlineClose size={24} />
            </button>
            <iframe
              width="315"
              height="560"
              src="https://www.youtube.com/embed/-JoLZ3sNIwA"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="z-50"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default YearRemindBanner;
