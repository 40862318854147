import { useStore } from '@globalStore/storeProvider';
import { globalFormFields, RCB_OPTION_LIST } from '@lib/constants';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import GlobalForm from '../global-form/GlobalForm';
import { LeadExistsCheck, LeadOnBoarding, SendOTP, VerifyOTP } from '@lib/api';
import { formValidation, otpFormValidation } from './validation';
import GlobalOTPForm from '../global-form/GlobalOTPForm';
import cn from 'classnames';
import useStorage from '@utils/hooks/useStorage';

import { loginToAnalyticsSvc, setAttributesInAnalytics } from '@lib/analytics/analytics.service';

export const courseInterestMap = {
  'data science': 'data-science',
};

function ApplicationForm({
  formContainerStyle,
  formLayout,
  FormHeader,
  SuccessScreen,
  source,
  buttonCTA,
  ...props
}) {
  const { getItemFromLocal } = useStorage();

  const { courseInterest } = useStore();

  const [formScreen, setFormScreen] = useState({
    otpScreen: false,
    successScreen: false,
  });

  const [refId, setRefId] = useState('');
  const [isLeadExists, setLeadExists] = useState(false);

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    graduationYear: '',
    phone: '',
    termNCondition: false,
    background: undefined,
    userType: undefined,
    courseInterest: courseInterestMap[courseInterest] ?? courseInterest,
  });

  const userId = useRef();

  const courseInterestOptions = useMemo(() => {
    return Object.entries(RCB_OPTION_LIST).map(([key, value]) => ({ label: value, value: key }));
  }, []);

  const switchScreenHandler = (payload) => {
    setFormScreen({
      ...formScreen,
      ...payload,
    });
  };

  const changeHandler = (e) => {
    const { type, name, value, checked } = e.target;

    const val = type === 'checkbox' ? checked : value;

    if (name === 'graduationYear') {
      setFormState({
        ...formState,
        graduationYear: val,
        background: undefined,
        userType: undefined,
      });

      return;
    }

    setFormState({
      ...formState,
      [name]: val,
    });
  };

  const onBoardingSubmitApi = async (formData) => {
    const { name, phone, ...rest } = formData;

    const subPlanType = sessionStorage.getItem('subPlanType');

    if (subPlanType) {
      formData.sub_plan_interest = subPlanType;
    }

    const fullName = name.split(' ');

    const payload = {
      firstName: fullName.shift(),
      lastName: fullName.join(' ') || '',
      phone: `+91${phone}`,
      source,
      ...(subPlanType && { subPlanType }),
      ...rest,
    };

    try {
      const leadData = await LeadOnBoarding(payload);

      userId.current = leadData?.onboardingInfo?.userId;

      if (userId.current) {
        loginToAnalyticsSvc(userId.current);

        window?.gtag?.('event', 'conversion', {
          send_to: 'AW-595701718/Zn11CL3fgvkCENbfhpwC',
          transaction_id: userId.current,
        });

        window?.gtag?.('event', 'conversion', {
          send_to: 'AW-16512768566/PmX8CIfhvaAZELa088E9',
          transaction_id: userId.current,
        });

        window?.fbq?.('track', 'CompleteRegistration', { eventID: userId.current });

        console.log('[Q event - C]');
      }
    } catch (error) {
      console.log('[OnBoarding ERROR]', error);

      throw Error('Request Failed, Try Later!');
    }
  };

  const checkLeadExist = async (email) => {
    try {
      const { exists } = await LeadExistsCheck(email, source);

      exists ? setLeadExists(true) : setLeadExists(false);
    } catch (err) {
      console.log('[LEAD CHECK ERROR]', err);
      throw Error('Request Failed, Try Later!');
    }
  };

  const handleRequestOtp = async (formData, isResendRequest) => {
    try {
      const { name, email, phone } = formData;

      setAttributesInAnalytics(name, email, phone);

      if (!isResendRequest) {
        await checkLeadExist(email);
      }

      const response = await SendOTP({ phone: `+91${phone}` });

      if (response.message === 'OTP Sent') {
        setRefId(response?.refId);

        await onBoardingSubmitApi(formData);

        sessionStorage.removeItem('subPlanType');

        switchScreenHandler({ otpScreen: false, successScreen: true });

        delete formState['otp'];

        localStorage.setItem('rcb_form_data', JSON.stringify(formState));

        // if (!isResendRequest) {
        //   switchScreenHandler({ otpScreen: true });
        // }
      }
    } catch (err) {
      console.log('[REQEUST OTP ERROR]', err);
      throw Error('Failed Sending OTP');
    }
  };

  const submitHandler = async (updatedFormData) => {
    const { email, phone } = updatedFormData;

    const userData = {
      sha256_email_address: email,
      sha256_phone_number: phone,
    };

    window.gtag && window.gtag('set', 'user_data', userData);

    try {
      await handleRequestOtp(updatedFormData);
    } catch (err) {
      throw Error(err?.message);
    }
  };

  const handleVerifyOtp = async (payload) => {
    try {
      const response = await VerifyOTP(payload);

      if (response.message == 'verification success') {
        sessionStorage.removeItem('subPlanType');

        switchScreenHandler({ otpScreen: false, successScreen: true });

        delete formState['otp'];

        localStorage.setItem('rcb_form_data', JSON.stringify(formState));
      } else {
        throw Error('Invalid OTP Entered!');
      }
    } catch (error) {
      console.log('[Verify OTP ERROR]', error);

      throw Error('Failed To Verify OTP!');
    }
  };

  const trackUserTypeLead = () => {
    const { userType } = formState;

    if (!userType || !userId.current) return;

    if (userType === 'student') return;

    console.log('[Q event - T]');

    window?.fbq?.('track', 'Lead', {
      content_category: userType === 'working' ? 'Working' : 'NonWorking',
      eventID: userId.current,
    });
    window?.gtag?.('event', 'conversion', {
      send_to:
        userType === 'working'
          ? 'AW-595701718/I602CMOx_IoaENbfhpwC'
          : 'AW-595701718/cvF-COGA3Z4YENbfhpwC',
      transaction_id: userId.current,
    });
    window?.gtag?.('event', 'conversion', {
      send_to: 'AW-16512768566/1dVzCJ_qvaAZELa088E9',
      transaction_id: userId.current,
    });
  };

  useEffect(() => {
    if (!formScreen.successScreen) return;

    trackUserTypeLead();
  }, [formScreen.successScreen]);

  useEffect(() => {
    const localFormData = getItemFromLocal('rcb_form_data');

    if (!!localFormData) {
      const localStoredFormData = JSON.parse(localFormData);

      delete localStoredFormData['courseInterest'];

      setFormState({
        ...formState,
        ...localStoredFormData,
      });
    }
  }, []);

  return (
    <>
      {FormHeader}

      <div className={formContainerStyle}>
        {(() => {
          switch (true) {
            case formScreen.otpScreen:
              return (
                <GlobalOTPForm
                  changeHandler={changeHandler}
                  formData={formState}
                  onResendOtp={handleRequestOtp}
                  refId={refId}
                  onSubmit={handleVerifyOtp}
                  validateOtp={otpFormValidation}
                />
              );

            case formScreen.successScreen:
              return <SuccessScreen {...formState} {...props} source={source} />;

            default:
              return (
                <GlobalForm
                  formData={formState}
                  changeHandler={changeHandler}
                  formLayout={cn('flex flex-col gap-y-[16px]', formLayout)}
                  onSubmit={submitHandler}
                  validate={formValidation}
                  fields={globalFormFields(courseInterestOptions)}
                  buttonCTA={buttonCTA || 'Continue'}
                />
              );
          }
        })()}
      </div>
    </>
  );
}

export default ApplicationForm;
