import { getImgUrl } from '@utils/functions/getImgUrl';
import { AiFillCheckCircle } from 'react-icons/ai';
import NextCohort from '../shared/NextCohort';
import { FaPlay } from 'react-icons/fa';
import ReactPlayer from 'react-player/lazy';
import { Primary, Secondary } from 'stories/Button.stories';
import StarRatings from 'react-star-ratings';
import useMediaQuery from '@utils/hooks/useMediaQuery';
import DarkLayout from '@components/shared/DarkLayout';
import Modal from '@components/shared/Modal';
import DemoClassFormButton, {
  DemoClassFormWithCloseBtn,
} from '../forms/demo-class-form/DemoClassFormButton';
import Image from 'next/image';
import { useDispatch } from '@globalStore/storeProvider';
import { sourceType } from '@components/shared/widgets/ContactUs';
import { NewRCBForm } from '@components/shared/forms/NewCallBackForm';

const Banner = ({
  courseData,
  queryString,
  nextCohort,
  degree,
  UGCourse,
  pgCourse = false,
  isWeb3Course = false,
}) => {
  const {
    courseName,
    courseImage,
    buttonText,
    buttonLink,
    freeTrialLink,
    freeTrialText,
    upcomingBatch,
    courseFeatures,
    learningJourneyElective,
    videoLink,
    courseType,
    planType,
  } = courseData;

  // const { startDate, batchName } = upcomingBatch?.batchDetails?.[0] || {};

  // const metricsData = {
  //   course: [
  //     { stat: '700+', text: 'hiring partners' },
  //     { stat: '8 LPA', text: 'avg salary' },
  //     { stat: '25 LPA', text: 'highest salary' },
  //   ],
  //   degree: [
  //     { stat: '700+', text: 'hiring partners' },
  //     { stat: UGCourse ? 'INR 15K/Month' : '8 LPA', text: UGCourse ? 'Avg Stipend' : 'avg salary' },
  //     ...(UGCourse ? [] : [{ stat: '40 LPA', text: 'highest salary' }]),
  //   ],
  // };

  const isMobile = useMediaQuery('(max-width: 767.98px)');
  const dispatch = useDispatch();

  const openRequestCallBack = () => {
    dispatch({
      type: 'SET_WIDGET_SOURCE',
      payload: sourceType.callback,
    });
  };

  return (
    <DarkLayout
      grid3D
      customStyle="items-start justify-center gap-x-6 px-6 py-12 pb-20 md:grid-cols-12 lg:px-20"
    >
      <section className="relative z-10 child:mb-6 md:col-span-6 child:md:mb-6">
        {/* <p
            style={{
              clipPath: 'polygon(0 0, 100% 0%, 85% 100%, 0% 100%)',
            }}
            className="-ml-6 w-fit whitespace-nowrap bg-gray-500 p-1.5 px-6 pr-12 text-base font-satoshi-medium font-medium  text-white lg:-ml-16 lg:px-16"
          >
            {degree ? 'AlmaBetter Innovarsity' : 'Pay after placement'}
          </p> */}

        <div className="!mb-2 flex items-center gap-x-[4px]">
          <p className="pb-[2px] font-satoshi-bold text-[18px] leading-7 text-white">4.6+</p>
          <div className="h-full pb-[4px]">
            <StarRatings
              rating={isWeb3Course ? 4.6 : 4.8}
              starRatedColor="#FDB022"
              numberOfStars={5}
              name="rating"
              starSpacing="4px"
              starDimension="20px"
            />
          </div>
          <p className="pt-[2px] font-satoshi-medium text-[12px] leading-4 text-gray-2">
            {isWeb3Course ? 1467 : 2354} Reviews
          </p>
        </div>
        <h1 className="!font-satoshi-bold text-3xl normal-case leading-8 text-white lg:text-5xl">
          {courseName}
        </h1>
        {!degree && <NextCohort data={nextCohort} showSlots={false} />}
        {!!courseData?.partnerUniversities?.length && (
          <div className="space-y-4">
            <p className="font-satoshi-medium text-xs font-semibold text-white">
              In collaboration with:
            </p>
            <div className="grid grid-cols-2 gap-x-6 gap-y-2 md:flex md:flex-wrap">
              {courseData?.partnerUniversities.map(({ Icon }, index) => (
                <Image
                  src={getImgUrl(Icon)}
                  alt="icon"
                  width={145}
                  height={45}
                  objectFit="contain"
                  key={index}
                  className="overflow-hidden rounded bg-white"
                />
              ))}
            </div>
          </div>
        )}
        {degree ? (
          <div className="flex flex-wrap items-center gap-2">
            <p className="font-satoshi-medium text-xs font-semibold">Specialisations in: </p>
            {learningJourneyElective.map(({ id, isSpecialisationTrack, learningJourney }) => (
              <>
                {isSpecialisationTrack &&
                  learningJourney?.map(({ title }) => (
                    <p
                      key={title}
                      className="w-fit bg-green-cyan p-0.5 px-1.5 font-satoshi-medium  font-satoshi-medium text-xs font-semibold capitalize text-blue-midnight"
                    >
                      {title}
                    </p>
                  ))}
              </>
            ))}
          </div>
        ) : null}
        <section className="flex flex-col gap-2">
          {courseFeatures?.map(({ feature }) => (
            <div
              className="flex items-start gap-4 font-normal child:font-satoshi-medium"
              key={feature}
            >
              <div className="mt-2 h-5 w-5">
                <AiFillCheckCircle className="text-[#20A898]" />
              </div>
              <p className="text-body !font-satoshi-regular !text-white">{feature}</p>
            </div>
          ))}

          {/* {!degree ? (
            <div className="flex items-center gap-4 font-normal child:font-satoshi-medium">
              <AiFillCheckCircle className="text-[#20A898]" size={20} />
              <p className="text-body">
                Next - <span className="font-satoshi-medium font-semibold text-red">{batchName}</span> starts on{' '}
                {dayjsWithAdvanceFormatPlugin(startDate).format('Do MMM')}
              </p>
            </div>
          ) : null} */}
        </section>
        {!degree ? (
          <>
            <section className="grid w-full grid-cols-2 items-center gap-x-[16px] md:flex">
              <Primary
                linkCTA
                href={queryString ? `${freeTrialLink}?${queryString}` : freeTrialLink}
                target="_blank"
                label={freeTrialText ?? 'explore program'}
                size={isMobile ? 'medium' : 'large'}
              />

              <Secondary
                linkCTA
                href={queryString ? `${buttonLink}?${queryString}` : buttonLink}
                target="_blank"
                label={buttonText}
                size={isMobile ? 'medium' : 'large'}
              />
            </section>
            {/* <div className="mt-8 flex w-full justify-between">
                {metricsData[degree ? 'degree' : 'course'].map(({ stat, text }) => (
                  <div key={`${stat}${text}`} className="text-center">
                    <p className="flex flex-col gap-y-1 text-3xl font-satoshi-medium font-semibold lg:text-4xl">
                      {stat}{' '}
                    </p>
                    <p className="text-body inline-block font-satoshi-medium capitalize">{text}</p>
                  </div>
                ))}
              </div> */}
          </>
        ) : (
          <section className="w-full space-y-2 lg:w-fit">
            <div className="grid h-14">
              {freeTrialText && (
                <Primary
                  linkCTA
                  href={queryString ? `${freeTrialLink}?${queryString}` : freeTrialLink}
                  target="_blank"
                  label={freeTrialText}
                  size="xl"
                  capitalCase
                  disabled={isWeb3Course}
                />
              )}
            </div>
            <NextCohort noBackground data={nextCohort} />
            {/* <section className="!mt-9 hidden flex-wrap items-center justify-center gap-x-20 gap-y-6 child:whitespace-nowrap child:text-center lg:flex lg:grid-cols-3 lg:flex-nowrap">
                {metricsData[degree ? 'degree' : 'course'].map(({ stat, text }) => (
                  <div key={`${stat}${text}`} className="text-center">
                    <p className="flex flex-col gap-y-1 text-4xl font-satoshi-medium font-semibold">{stat} </p>
                    <p className="text-body inline-block font-satoshi-medium">{text}</p>
                  </div>
                ))}
              </section> */}
          </section>
        )}
      </section>
      <div className="md:col-span-6" id="callback">
        {degree || pgCourse ? (
          <>
            <div
              id="contact-form"
              className="relative ml-auto h-full w-full space-y-5 overflow-hidden lg:overflow-visible lg:py-12"
            >
              <div className="flex flex-col items-center gap-y-[16px]">
                <VideoSection
                  courseType={courseType}
                  videoLink={videoLink}
                  isWeb3Course={isWeb3Course}
                />

                <div className="flex items-center gap-x-4">
                  {!isWeb3Course && (
                    <DemoClassFormButton
                      label={'Book a Demo Class'}
                      size="large"
                      centerModal
                      capitalCase={false}
                      outline
                    />
                  )}

                  <Secondary
                    label={'Request Call Back'}
                    size={'large'}
                    onClick={openRequestCallBack}
                  />
                </div>
              </div>

              {!pgCourse && (
                <div className="flex items-center justify-center gap-4">
                  <img src={'/assets/degrees/rating.png'} alt="rating" className="h-14 md:h-20" />
                  <img
                    src={
                      UGCourse
                        ? '/assets/degrees/DiplomaPlacedImg.png'
                        : '/assets/degrees/getPlaced.png'
                    }
                    alt="degree form"
                    className="h-14 md:h-20"
                  />
                </div>
              )}
            </div>
            {/* <section className="mt-12 lg:hidden">
              <AccreditationList />
            </section> */}
            {/* <section className="mt-6 flex items-center justify-between child:whitespace-nowrap child:text-center lg:hidden lg:grid-cols-3 lg:flex-nowrap lg:justify-center lg:gap-x-20">
                {metricsData[degree ? 'degree' : 'course'].map(({ stat, text }) => (
                  <div key={`${stat}${text}`} className="text-center">
                    <p className="flex flex-col gap-y-1 text-3xl font-satoshi-medium font-semibold lg:text-4xl">
                      {stat}{' '}
                    </p>
                    <p className="text-body inline-block font-satoshi-medium capitalize">{text}</p>
                  </div>
                ))}
              </section> */}
          </>
        ) : (
          <>
            {!videoLink ? (
              <img
                // priority
                src={getImgUrl(courseImage)}
                // height={550}
                // width={400}
                className="ml-auto object-contain lg:h-128"
                alt="icon"
                // objectFit="contain"
              />
            ) : (
              <div className="relative ml-auto flex flex-col items-center justify-center space-y-8  lg:h-[31rem] lg:overflow-visible">
                <div className="absolute -top-[4rem] right-0 -z-10 h-[120%] w-[11rem] rotate-[50deg] rounded-3xl bg-[#FBEAEA] lg:right-[8rem] lg:-top-[14rem] lg:h-[150%] lg:w-[13rem]" />
                <VideoSection videoLink={videoLink} />
                <section>
                  <div className="flex flex-wrap items-center justify-center gap-4 pb-4 lg:gap-2">
                    {[
                      {
                        name: 'john josh',
                        designation: 'Assistant Professor',
                        company: 'IIT, Guwahati',
                        img: 'iit.png',
                      },
                      {
                        name: 'Shivam Dutta',
                        designation: 'Co-Founder & CEO',
                        company: 'Almabetter',
                        img: 'alma.png',
                      },
                    ].map(({ name, designation, company, img }) => (
                      <div
                        key={name}
                        className="flex items-center gap-2 rounded-md bg-white px-1.5 py-1.5 shadow-md "
                      >
                        <img
                          src={`/assets/iit-leaders/${img}`}
                          className="w-10 bg-[#FBEAEA] lg:w-12"
                          alt={name}
                        />
                        <div className="flex-2">
                          <p className="font-satoshi-regular text-sm capitalize">
                            {name}
                            <span className="block text-xs font-normal opacity-50">
                              {designation}
                            </span>
                            <span className="block text-xs font-normal opacity-50">{company}</span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            )}
          </>
        )}
      </div>
    </DarkLayout>
  );
};

export default Banner;

export const VideoSection = ({ videoLink, children, isWeb3Course }) => {
  return (
    <div className="relative h-fit w-full overflow-hidden rounded-lg border-4 border-gray/30 lg:h-full lg:w-full">
      <Modal open={<VideoSectionThumb>{children}</VideoSectionThumb>} boxStyle={'md:w-4/6'}>
        <VideoAndFormLayout videoLink={videoLink} isWeb3Course={isWeb3Course} />
      </Modal>
    </div>
  );
};

function VideoSectionThumb({ children, onClick }) {
  return (
    <>
      <div
        onClick={() => {
          onClick && onClick();
        }}
        className="center absolute top-1/2 left-1/2 z-10 h-10 w-10 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full bg-white"
      >
        <FaPlay className="text-red" size={20} />
      </div>
      {children ? (
        children
      ) : (
        <div className="relative flex items-end justify-start">
          <img
            src="/assets/partners-bg/new-IIT-Guwahati-bg.webp"
            alt="iit-guwahati"
            className="h-full w-full"
          />

          <div className="absolute inset-0 h-full w-full bg-black bg-opacity-30"></div>
          <p className="absolute p-[16px] font-satoshi-bold text-[20px] leading-8 text-white md:p-[24px] md:text-[30px] md:leading-tight">
            Introducing Advanced Certification from E&ICT Academy, IIT Guwahati
          </p>
        </div>
      )}
    </>
  );
}

function VideoAndFormLayout({ videoLink, closeModal, isWeb3Course = false }) {
  const isMobile = useMediaQuery('(max-width: 767.98px)');

  return (
    <div className="flex flex-col items-center overflow-hidden rounded-lg bg-white bg-[#F9FAFB] md:grid md:grid-cols-5 md:items-start">
      <div className="flex flex-col gap-y-[16px] pt-[16px] md:col-span-3 md:gap-y-[24px] md:p-[20px]">
        <p className="px-[16px] font-satoshi-bold text-[24px] leading-8 md:p-0">Course Preview</p>
        {isMobile ? (
          <ReactPlayer
            url={videoLink}
            controls
            playing
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
            height={300}
            width={440}
          />
        ) : (
          <ReactPlayer
            url={videoLink}
            playing={true}
            controls
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
        )}
      </div>
      <div
        className="h-full w-full bg-white md:col-span-2 md:place-self-end md:self-center"
        style={{
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        }}
      >
        {isWeb3Course ? (
          <NewRCBForm
            source={'Application_enquiry_form'}
            closeModal={closeModal}
            formContainerStyle={'slim-scroll h-full max-h-[45vh] md:max-h-[50vh]'}
          />
        ) : (
          <div className="slim-scroll h-full max-h-[55vh] bg-white pr-[2px] md:max-h-[70vh]">
            <DemoClassFormWithCloseBtn closeModal={closeModal} />
          </div>
        )}
      </div>
    </div>
  );
}
