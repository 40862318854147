import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { getImgUrl } from '@utils/functions/getImgUrl';
import dayjs from 'dayjs';
import { useDispatch } from '@globalStore/storeProvider';
import { sourceType } from '@components/shared/widgets/ContactUs';
import { PrimaryBadge } from 'stories/Badge.stories';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { Primary, Secondary } from 'stories/Button.stories';

function EventBannerDesk({ events, showJoin, path, registeredEventId = [] }) {
  const dispatch = useDispatch();

  const openRCBPopup = () => {
    dispatch({
      type: 'SET_WIDGET_SOURCE',
      payload: sourceType.pageBanner,
    });
    return;
  };

  const EventRegisteration = () => {
    dispatch({
      type: 'SET_EVENT_DATA',
      payload: events,
    });

    openRCBPopup();
  };

  const JoinEvent = () => {
    if (registeredEventId?.includes(events?.eventId)) {
      window.open(`${events?.videoLink}`, '_blank');
    } else {
      dispatch({
        type: 'SET_EVENT_DATA',
        payload: events,
      });

      openRCBPopup();
    }
  };

  const {
    instructor: {
      data: [{ attributes: instructorData }],
    },
  } = events;

  return (
    <div className="flex flex-col items-center gap-y-[32px] px-[16px] pt-[32px] pb-[76px] md:gap-y-[72px] md:px-[124px] md:py-[112px]">
      <h2 className="z-1 relative flex items-center gap-x-[10px] text-center font-satoshi-bold text-[30px] leading-9 md:text-[36px] md:leading-none">
        Free Upcoming
        <span className="relative -top-2 text-red underline underline-offset-4">Masterclass</span>
      </h2>

      <div className="eventCard--dark relative grid items-center gap-y-[100px] overflow-hidden md:grid-cols-12">
        <div className="relative z-10 flex flex-col gap-y-[20px] px-[24px] pb-[49px] md:col-span-6	md:col-start-2 md:py-[39px] md:px-0">
          <div className="flex flex-col items-start gap-y-[16px]">
            <PrimaryBadge
              label={events?.EventType || events?.eventType}
              backgroundColor={'transparent'}
              size={'large'}
              customStyle={'hidden md:block'}
            />
            <h3 className="font-satoshi-bold text-[24px] leading-8 text-white md:text-[30px] md:leading-[38px]">
              {events?.shortTitle || events?.title}
            </h3>
            <div className="flex items-start gap-x-[8px] md:pl-[16px]">
              <IoIosCheckmarkCircleOutline className="text-red" size={20} />
              <p className="font-satoshi-medium text-[14px] leading-5 text-white">
                {dayjs(`${events?.eventDate}T${events?.startTime}`).format('ddd, DD MMM YYYY')}
              </p>
            </div>
            <div className="flex items-start gap-x-[8px] md:pl-[16px]">
              <IoIosCheckmarkCircleOutline className="text-red" size={20} />
              <p className="font-satoshi-medium text-[14px] leading-5 text-white">
                {dayjs(`${events?.eventDate}T${events?.startTime}`).format('h A')} -{' '}
                {dayjs(`${events?.eventDate}T${events?.endTime}`).format('h A')}
              </p>
            </div>
            <div className="flex items-start gap-x-[8px] md:pl-[16px]">
              <IoIosCheckmarkCircleOutline className="text-red" size={20} />
              <p className="font-satoshi-medium text-[14px] leading-5 text-white">
                Online Live Session
              </p>
            </div>
          </div>
          <div className="grid gap-[12px] md:flex md:items-center">
            {!showJoin ? (
              <Primary label={'Register for FREE!!'} onClick={EventRegisteration} />
            ) : (
              <Primary label={'Join Now'} onClick={JoinEvent} />
            )}

            <Secondary label={'Learn more'} linkCTA href={`${path}${events?.slug}`} />
          </div>
          <p className="font-satoshi-medium text-[14px] leading-5 text-gray-2">
            Hurry Up Limited Seats Available!
          </p>
        </div>

        <div className="relative order-first h-full w-full md:order-none md:col-span-5">
          <Image
            src={getImgUrl(instructorData.DisplayPicture)}
            layout="responsive"
            width={440}
            height={410}
            objectFit="cover"
          />

          <div className="absolute -bottom-12 left-10 flex flex-col gap-y-[4px] rounded-2xl bg-[#f1f5f9] bg-opacity-40 py-[12px] px-[28px] text-white backdrop-blur-md md:-left-24 md:bottom-6 md:z-20 md:py-[20px] md:px-[16px]">
            <h4 className="font-satoshi-bold text-[16px] leading-6 md:text-[20px] md:leading-[30px]">
              {instructorData.Name}
            </h4>
            <div className="flex flex-col gap-y-[8px]">
              <p className="font-satoshi-medium text-[12px] leading-[18px] md:text-[16px] md:leading-6">
                {instructorData.Designation}
              </p>
              <div className="flex items-center gap-x-[10px]">
                <img
                  src={getImgUrl(instructorData.CompanyImage)}
                  className="h-10 w-20 cursor-pointer object-contain transition duration-500 hover:scale-150"
                />

                {instructorData.pastCompanies.slice(0, 2).map((company) => (
                  <img
                    key={company.id}
                    src={getImgUrl(company.companyImage)}
                    className="h-10 w-20 cursor-pointer object-contain transition duration-500 hover:scale-150"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventBannerDesk;
