import ChipsLayout from '@components/shared/ChipsLayout';
import useMediaQuery from '@utils/hooks/useMediaQuery';
import React from 'react';

const chipsData = [
  {
    subject: 'Students',
    point: '30,000+',
  },
  {
    subject: 'Highest Salary',
    point: '40 LPA',
  },
  {
    subject: 'Average Salary',
    point: '15 LPA',
  },
  {
    subject: 'Hiring Partners',
    point: '700+',
  },
];

function Placements({ DownloadPlacementReport, title, subTitle, description }) {
  const isMobile = useMediaQuery('(max-width: 767.98px)');

  return (
    <div
      id="placementReport"
      className="flex flex-col items-center gap-y-[64px] px-[16px] pt-[32px] pb-[44px] md:p-[124px]"
    >
      <div className="flex w-4/5 flex-col items-center gap-y-[16px]">
        <h2 className="text-center font-satoshi-medium font-satoshi-medium text-[30px] font-semibold font-bold leading-9 md:font-satoshi-bold md:font-satoshi-bold md:text-[36px]  md:leading-none">
          {title || 'Industry Leading'}{' '}
          <span className="relative -top-2 text-red underline underline-offset-8">
            {subTitle || 'Placements'}
          </span>
        </h2>
        <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-gray-400 md:text-[18px] md:leading-7">
          {description ||
            `Kick start your Data Science career today with our flagship Masters Degree program. Learn
          Advanced Data Science with top engineers. Challenge yourself by building real time
          projects.`}
        </p>
      </div>
      <ChipsLayout
        chipsData={chipsData}
        customStyle="pb-[16px]"
        chipStyle="flex flex-col-reverse items-center gap-y-[12px]"
        pointStyle="font-satoshi-bold text-[36px] leading-none"
        subjectStyle="font-satoshi-regular text-[20px] leading-[30px] text-gray-400"
        isMobile={isMobile}
      />
      {DownloadPlacementReport && <DownloadPlacementReport size="large" capitalCase={false} />}
    </div>
  );
}

export default Placements;
