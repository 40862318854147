import React, { useEffect, useState } from 'react';
import { navbarRef } from './HeaderComponent';
import { useRouter } from 'next/router';
import { useDispatch, useStore } from '@globalStore/storeProvider';

import axios from 'axios';
import { JOB_BOARD_COMPILER_BASE_URL } from '@lib/api';
import DemoClassFormButton from '@components/local/courses/forms/demo-class-form/DemoClassFormButton';

import cn from 'classnames';
import woodStyleClockIcon from '@assets/bytes/clock-front-color.png';
import Image from 'next/image';
import { DateTimeFormatter } from '@utils/utils';
import { Primary } from 'stories/Button.stories';
import { sourceType } from '../widgets/ContactUs';

function EventBanner() {
  const [isStripOpen, setIsStripOpen] = useState(true);

  const { pathname } = useRouter();

  const { nextCohort } = useStore();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_STRIP_BANNER', payload: true });
  }, []);

  const openCallbackWidget = () => {
    dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.callback });
  };

  return (
    <>
      {isStripOpen && (
        <>
          {/* Dektop */}
          <section className="sticky top-[4rem]  z-40 hidden h-[4rem] w-full items-center justify-evenly bg-[url('/assets/strips/Ind-desktop.png')] bg-cover font-satoshi-medium  text-[14px] font-medium leading-5 md:flex md:h-14 md:justify-center md:py-2 md:pr-0 md:text-sm">
            <div className="flex items-center justify-center gap-2">
              <div className={'flex items-center gap-2'}>
                <img src="/assets/strips/flag.png" alt="" className="ml-2 h-6" />
                <p className="self-start font-satoshi-bold text-[18px] leading-6">
                  Celebrate Republic Day With AlmaBetter
                </p>
              </div>
              <div className="flex items-center gap-2">
                <img src="/assets/strips/clock.png" alt="" className=" h-6" />
                <p className="font-satoshi-medium">Grab exclusive offers while seats last!</p>
              </div>

              {/* <div className={'flex'}>
                {nextCohort ? (
                  <div className="flex items-center gap-2">
                    <img src="/assets/strips/clock.png" alt="" className="h-6" />
                    <p className="text-[14px] font-satoshi-medium leading-5">
                      Next cohort starts on {DateTimeFormatter(nextCohort?.startDate, '', true)}
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <img src="/assets/strips/clock.png" alt="" className=" h-6" />
                    <p className="font-satoshi-medium">Limited Seats Available</p>
                  </div>
                )}
              </div> */}

              <div className={''}>
                {/* <DemoClassFormButton
                  label={'Book Now'}
                  centerModal
                  size="small"
                  capitalCase={false}
                /> */}
                <Primary
                  label={'Claim Now!'}
                  // customStyle={' px-1 py-2'}
                  // size={'xl'}
                  onClick={openCallbackWidget}
                />
              </div>

              {/* <button
              className="absolute place-self-end self-start font-satoshi-bold text-2xl !leading-3 text-white md:text-3xl"
              onClick={closeStripBanner}
            >
              &times;
            </button> */}
            </div>
          </section>
          {/* Mobile */}
          <div className="sticky top-[4rem] z-40 grid h-auto w-full grid-rows-[1fr] bg-[url('/assets/strips/ind-mob.png')] bg-cover p-4 font-satoshi-medium text-[14px] leading-5 md:hidden">
            <div className="grid grid-cols-[1fr_3fr_2fr]">
              <img src="/assets/strips/flag.png" alt="" className="mr-1 h-[3rem] py-1" />
              <div>
                <p className="self-start font-satoshi-bold text-[16px] leading-6">
                  Celebrate Republic Day
                </p>
                <p className="text-[14px] leading-6">with exclusive offers!</p>
              </div>
              <div className="flex items-center justify-center">
                <Primary
                  label={'Claim Now!'}
                  // size={'md'}
                  // customStyle={'text-[18px] font-medium'}
                  onClick={openCallbackWidget}
                />
                {/* <DemoClassFormButton
                  label={'Book Now'}
                  centerModal
                  size="small"
                  capitalCase={false}
                /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EventBanner;

async function fetchUpcomingDemoClass() {
  const {
    data: { upcomingClass },
  } = await axios.get(`${JOB_BOARD_COMPILER_BASE_URL}/api/website/demo-class/upcoming`);

  return upcomingClass;
}
